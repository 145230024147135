/* styles.css */
h5,
p {
  margin: 0;
}

body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0;
}
/* NavigationMenu */
.navigation-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #000000;
  color: #fff;
}

.logo {
  font-weight: bold;
  margin-right: 3rem;
}

.logo img {
  width: 130px;
  padding: 20px;
}

.menu-links {
  list-style: none;
  display: flex;
  gap: 3.5rem;
  align-items: center;
}

.menu-links li button {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}

.menu-links li button:hover {
  text-decoration: underline;
}

.search {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 0 15px;
}

.search input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 59px;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 20px;
  padding: 8px 20px;
  font-size: 12px;
  color: #333;
  background-image: url('/public/icons/arrow.png');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1em auto, 100%;
}

.category-list {
  list-style: none;
  display: flex;
  gap: 10px;
  padding: 0;
}

.category-list li {
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.category-list li:hover {
  background-color: #f1f1f1;
}

.search button {
  padding: 8px 16px;
  background-color: #bf0000;
  color: #fff;
  border: none;
  border-radius: 59px;
  cursor: pointer;
}

.search button:hover {
  background-color: #de0000;
}

.mobile-search,
.mobile-menu-overlay .hamburger-menu {
  display: none;
}

.mobile-search {
}

/* MovieContainer */
.movie-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* MovieCard */
.movie-card {
  width: 280px;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid black;
}

.movie-card .header {
  height: 250px;
  background-size: 100% 100%, 0;
  background-position: center;
  background-repeat: no-repeat;
}

.movie-card .info {
  padding: 15px 25px;
  padding-bottom: 10px;
}

.movie-card .rating,
.movie-details .title .rating {
  font-size: 18px;
  color: #3644b5;
  margin-bottom: 8px;
}

.movie-card .title {
  font-size: 18px;
  color: #4b4b4d;
}

.movie-card .description {
  font-size: 12px;
  color: #6c757d;
}

.movie-card .watch-button,
.movie-details .info .watch-button {
  background-color: #bf0000;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.movie-card .watch-button:hover,
.movie-details .info .watch-button:hover {
  background-color: #e20101;
}

/* Pagination */

.pagination-container {
  display: flex;
  gap: 35px;
  justify-content: center;
  margin: 3rem 0;
}

.pagination-container span {
  cursor: pointer;
  margin: 5px;
  background-color: #4b4b4d;
  color: white;
  padding: 10px 14px;
  font-weight: bold;
  border-radius: 50%;
}

.pagination-container span:hover {
  background-color: #7c7c7d;
}

.pagination-container .active {
  font-weight: bold;
  background-color: #bf0000;
}

/* Movie Details */
.movie-details {
  display: flex;
  align-items: center;
  gap: 5rem;
  padding: 4.5rem;
  margin: 0 auto;
}

.movie-details .info {
  align-self: flex-start;
}

.movie-details .info .title {
  display: flex;
  justify-content: flex-start;
  gap: 5rem;
  align-items: center;
  margin-bottom: 1rem;
  font-weight: bold;
}

.movie-details .info .title h2 {
  margin: 0;
  color: #bf0000;
}
.movie-details .info .description {
  margin-bottom: 20px;
}

.movie-details .info .actor {
  display: block;
  margin-bottom: 0.5rem;
}

.movie-details .info .watch-button {
  width: 30%;
  padding: 1rem;
  margin-top: 1.5rem;
  font-weight: bold;
  font-size: 20px;
}

.similar-movies-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
  align-items: center;
  justify-content: space-between;
  align-content: space-between;
  justify-items: center;
}

/* About */

.about {
  padding: 8rem;
  padding-top: 5rem;
}

.about p {
  font-weight: bold;
  font-size: 18px;
  margin: 1rem 0;
  color: #4b4b4d;
  line-height: 1.2;
}

/* ActorDetails */
.actor-details {
  text-align: center;
  padding: 0 5rem;
  margin-top: 3rem;
}

.actor-img {
  max-width: 12rem;
}

.actor-name {
  color: #bf0000;
}

.actor-nmovies {
  margin-top: 1.5rem;
  color: #4b4b4d;
  font-size: 21px;
}

/* Footer */
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f0e5b2;
  padding-top: 2rem;
}
.footer h5 {
  font-size: 30px;
  color: #bf0000;
}
.footer .social {
  margin: 2rem 0;
  display: flex;
  gap: 3rem;
}

.footer .social img {
  width: 40px;
  cursor: pointer;
}

.footer .logo {
  display: flex;
  align-items: center;
}

.footer .logo h4 {
  font-size: 47px;
  color: #bf0000;
  margin: 0;
}
.footer .description {
  width: 45%;
  font-size: 16px;
  text-align: left;
  margin-bottom: 2rem;
}

.footer .bar {
  width: 100%;
  background-color: #000000;
  text-align: center;
  color: white;
  font-size: 14px;
  padding: 0.5rem 0rem;
  font-style: italic;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .navigation-menu {
    border-radius: 0 0 23px;
  }
  .menu-links {
    display: none;
  }

  .search {
    display: none;
  }

  .mobile-search {
    display: flex;
    justify-items: center;
    padding: 2rem 4rem;
  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
    padding: 10px;
    position: relative;
    right: 35px;
  }

  .hamburger {
    width: 30px;
    height: 3px;
    background-color: #fff;
    position: relative;
    margin-top: 4px;
    border-radius: 4px;
  }

  .hamburger:before,
  .hamburger:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: #fff;
    border-radius: 4px;
  }

  .hamburger:before {
    top: -10px;
  }

  .hamburger:after {
    bottom: -10px;
  }

  .hamburger.active:before,
  .hamburger.active:after {
    top: 0;
  }

  .hamburger.active {
    background-color: transparent;
  }

  .hamburger.active:before {
    transform: rotate(45deg);
  }

  .hamburger.active:after {
    transform: rotate(-43deg);
    right: -1px;
  }

  .navigation-menu.mobile-menu-active {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hamburger-menu.active .hamburger {
    background-color: transparent;
  }

  .mobile-menu-overlay {
    position: fixed;
    width: 100%;
    height: 85%;
    top: 9.6rem;
    left: 0;
    background-color: rgb(0 0 0);
    z-index: 999;
  }

  .mobile-menu-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    gap: 3rem;
    font-size: 25px;
    margin-top: 5rem;
    padding: 0;
    width: 100%;
    font-weight: bold;
  }

  .mobile-menu-links li {
    border-bottom: 1px solid #736e6e;
    width: 100%;
    text-align: center;
    padding-bottom: 2rem;
  }

  .mobile-menu-links li button {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    color: white;
    text-decoration: none;
  }

  .hamburger-menu.active .hamburger::before {
    top: -10px;
    rotate: 50deg;
  }
  .hamburger-menu.active .hamburger::after {
    bottom: 10px;
    rotate: -50deg;
    left: 0px;
  }

  .movie-card {
    width: 350px;
  }

  .movie-details,
  .movie-details .info .title {
    flex-direction: column;
  }

  .movie-details {
    gap: 1rem;
  }
  .movie-details .info .title {
    gap: 0;
  }

  .movie-details .info .watch-button {
    width: 100%;
  }

  .similar-movies-grid {
    grid-template-columns: 1fr;
  }

  .footer .logo {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .footer .logo h4 {
    font-size: 5vw;
    margin: revert;
    margin-top: -40px;
  }
  .footer .description {
    width: 70%;
  }
}

@media (max-width: 425px) {
  .pagination-container {
    gap: 0px;
    scale: 0.8;
  }
  .pagination-container span {
    scale: 0.8;
  }
}

.loop {
  background-image: url('/public/icons/loop.png');
  background-position: center;
  background-size: 2.5rem auto, 100%;
  width: 100px;
  height: 50px;
  margin: 0 auto;
  background-repeat: no-repeat;
}
